import React, { useState } from 'react';
import './ImageGenerator.css';

const ImageGenerator = () => {
  const [prompt, setPrompt] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const generateImage = async () => {
    if (!apiKey) {
      alert('Please enter your Stability AI API key');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://api.stability.ai/v1/generation/stable-diffusion-v1-6/text-to-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          text_prompts: [{ text: prompt }],
          cfg_scale: 7,
          height: 512,
          width: 512,
          samples: 1,
          steps: 30,
        })
      });

      if (!response.ok) {
        throw new Error('API request failed');
      }

      const data = await response.json();
      const base64Image = data.artifacts[0].base64;
      setImageUrl(`data:image/png;base64,${base64Image}`);
    } catch (error) {
      console.error('Error generating image:', error);
      alert('Failed to generate image. Please check your API key and try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="image-generator">
      <h1>AI Image Generator</h1>
      <div className="input-container">
        <input
          type="password"
          placeholder="Enter your Stability AI API key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          className="input-field"
        />
        <input
          type="text"
          placeholder="Enter your prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className="input-field"
        />
        <button
          onClick={generateImage}
          disabled={loading || !prompt || !apiKey}
          className="generate-button"
        >
          {loading ? 'Generating...' : 'Generate Image'}
        </button>
      </div>
      {imageUrl && (
        <div className="image-container">
          <img src={imageUrl} alt="Generated image" className="generated-image" />
        </div>
      )}
    </div>
  );
};

export default ImageGenerator;